.autocomplete {
	position: relative;
	display: inline-block;

	.autocomplete-results {
		position: absolute;
		border: 2px solid #d4d4d4;
		border-bottom: 2px solid #d4d4d4;
		border-top: none;
		z-index: 99;
		/*position the autocomplete items to be the same width as the container:*/
		left: 0;
		right: 0;
		overflow: hidden;
		transition: all 0.3s ease-in-out;
		background: $white;
		padding: 0px;
	}

	.autocomplete-results.show {
		height: 300px;
		overflow: scroll;
	}

	.autocomplete-results.hide {
		height: 0px;
		border-bottom: none;
	}

	.autocomplete-items {
		width: 100%;
		padding: 10px;
		cursor: pointer;
		background-color: #fff; 
		border-bottom: 2px solid #d4d4d4;
		&:hover {
			color: $white;
			background-color: #899097;
		}
	}
}