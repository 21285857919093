/**
*   card-incident.scss
*   @description: Estilos de tarjetas de incidencias
*/

.card-incident {

    .name { width: 20%; }

    .description { width: 50%; }

    .description-detail { font-size: $fontText; }

    .vivienda { width: 10%; }

    .fecha { width: 20%; }

    td { border-bottom: 2px solid $light; }

}