/**
*   login.scss
*   @description: Estilos para la página de Login
*/

.login {
    height: 100vh;

    .content { width: 100%; }

    .content-gral {
        width: 100%;

        .left-content {
            height: 100vh;

            img {
                height: 100vh;
                max-width: max-content;
            }
        }

        .right-content {
            width: 100%;

            .card-login {
                width: 80%;
                padding: $paddingMedium;
                background-color: $white;
                //box-shadow: 1px 0 20px rgba(0,0,0,.05);

                img { height: 150px; }

                .btn-primary {
                    background-color: rgba(34, 37, 72, 1);
                }
            }
        }
    }

    .input-container {
        padding: 0 $paddingLarge;
        input {
            width: 100%;
            border-radius: 4px;
            border: 2px solid rgba($gray, 0.5);
            padding: $paddingNormal $paddingNormal;
        }
    }

    .pass-eye {
        position: relative;

        i {
            position: absolute;
            top: 14px;
            right: 48px;
        }
    }

    .btn-container {
        padding: 0 $paddingLarge;
        button {
            width: 100%;
            padding: $paddingNormal;
        }
    }    
}

@media screen and (min-width: 1500px) {
    .card-login {
        width: 70% !important;

        h1 {
            font-size: 2rem;
        }

        input, span {
            font-size: 1rem;
        }
    }

    .pass-eye {
        position: relative;

        i {
            position: absolute;
            top: 17px !important;
            right: 50px !important;
            font-size: 1.1rem !important;
        }
    }

    a {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 1700px) {
    .card-login {
        width: 70% !important;

        h1 {
            font-size: 2.3rem;
        }

        .responsive-img {
            img {
                height: 200px;
            }
        }

        h3 {
            font-size: 1.7rem;
        }

        input, span {
            font-size: 1.2rem;
        }
    }

    .pass-eye {
        position: relative;

        i {
            position: absolute;
            top: 17px !important;
            right: 50px !important;
            font-size: 1.3rem !important;
        }
    }

    a {
        font-size: 1.3rem;
    }
}