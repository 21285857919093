// COLORES PRINCIPALES DEL MODAL

$success: #6c757d;
$successLight: #6c757d86;
$cancel: #ea4235;
$cancelLight: #eb5b51;
$cancelHover: #9e9e9e85;

$overlay: rgba(0, 0, 0, 0.5);
$header: #000000;
$hoverBtnClose: #6c757d86;
$background:#FFFFFF;

// ESTILOS GENERALES 
@mixin button-action {
	width: 25%;
	padding: 6px 10px;
	margin-right: 8px;
	font-size: 1rem;
	font-weight: 800;
	color: white;
	transition: all 300ms ease-in-out;
	i {
		font-size: 1rem;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		text-shadow: 0px 0px 6px rgba(255, 255, 255, .4);
		-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		transition: all 0.4s ease 0s;
	}
}
// TAMAÑO MODAL

.dinamic-modal-small {
	width: 45%;
}

.dinamic-modal-normal {
	width: 50%;
}

.dinamic-modal-big {
	width: 57%;
}
.dinamic-modal-super-big {
	width: 80%;
}

.dinamic-modal-medium {
    width: 75%;
}

.label-restaurant {
	width: 200px;
	margin-right: 8px;
}

.label-big-restaurant {
	width: 300px;
	margin-right: 8px;
}

.img-restaurant {
	display: flex;
    flex-direction: column;
    position: relative;
	height: 150px;
	margin-left: 7px;
	margin-top: 21px;
	.container-img {
		position: relative;
		width: 100%;
		min-width: 328px;

		.img-duo-preview {
			min-height: 160px;
			max-height: 160px;
		}
	}
}
// ESTILOS CUERPO MODAL

.dinamic-modal-container {
	// top: 50%;
    // left: 50%; 
    outline: none;
    max-height: 100%;
   	//overflow-y: scroll;
    position: absolute;
    border-radius: 4px;
    //padding: $paddingMedium;
	background-color: $white;
	transition: max-height 1.5s ease-in-out;
    transform: translate(-50%, -50%);
	z-index: 5;
	
	.title {
		text-align: left;
	}

	.modal-container {
		width: 95%;
	}

	.dinamic-header {
		&:hover{
			cursor: pointer;
		}
		//padding: 8px 0px 8px 0px;
		border-radius: 4px 4px 0 0;
		//background-color: #ea545f;
		background-color: $purple;
		width: 100%;
		height: 31px;
		position: relative;
		.title {
			color: white;
			font-weight: 400;
			font-size: 1.1rem;
		}

		.btn-modal-close {
			position: absolute;
			right: 18px;
			top: 30%;
			width: auto;
			//height: 25px;
			//width: 25px;
			//border-radius: 50%;
			//background-color: $header;
			color: white;
			font-size: 0.9rem;
			transition: background-color 400ms ease-in-out;

			&:hover {
				//color: $primary;
				//background-color: $successLight;
			}
		}
	}

	.dinamic-body {
		height: auto;
		max-height: 750px;
		overflow-y: auto;
	}

	.dinamic-footer {
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.btn-action-success {
			//@include button-action;
			//background-color: $success;
			width: 100px;
			padding: 3px;
			//margin-right: 8px;
			font-size: 0.93rem;
			font-weight: 500;
			color: white;
			transition: all 300ms ease-in-out;
			//background-color: #ea545f;
			background-color: $purple;

			&:hover {
				//background-color: #8c949b;
				//background-color: #ea3542;
				background-color: rgba($purple, 0.8);
			}
		}

		.btn-action-cancel {
			/*@include button-action;
			background-color: $cancel;*/
			width: 100px;
			padding: 3px;
			margin-right: 8px;
			font-size: 0.93rem;
			font-weight: 500;
			color: $purple;
			transition: all 300ms ease-in-out;
  			background-color: #9e9e9e59;

			&:hover {
				background-color: $cancelHover;
			}
		}
	}
	.itinerary {
		width: 65%;
	}
	.schedule{
		width: 35%;
		padding-left: 16px;
		padding-top: 8px;
		font-family: "Mukta", sans-serif ;
		color: $dark;
		border-left: 1px solid #eee;
		margin-left: 16px;

		.title{
			display: flex;
			align-content: flex-end;
			justify-content: space-between;
			.textFilter{
				box-shadow: 0px 2px 14px 0px rgba(234, 84, 95, 0.52);
    			box-shadow: 2px 1px 5px #aeaeae;
			}
			.clear{
				height: auto;
			}
		}
		.check{
			padding-left: 16px;
		}
		.columns-ininerary{

			// display: 		flex;
			// flex-wrap: 		wrap;
			height:			400px;
			overflow-y: 	auto;
			padding-right: 	0px;
			margin-right: 0px;
			// padding-bottom: 0px;
			.item{
				display: 			flex;
				justify-content: 	space-between;
				align-content: 		center;
				width: 				100%;
				padding-left: 		5px;
				padding-right: 		0px;
				margin-bottom: 		0px;
				margin-right: 		0px;
				margin-bottom: 		8px;
				// height: 30px;
				.check{
					align-self: center;
				}
				&:hover{
					background: #FFEBEB;
					cursor: pointer;
				}

			}
			.item:nth-child(odd) {
				// background-color:red;
			}
		}
	}

	p, b {
		font-weight: 500;
	}
}

.overlay {
	top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: height 1500ms ease-in-out;
    background-color: $overlay;
    ::-webkit-scrollbar { width: 10px; }
	::-webkit-scrollbar-thumb { background-color: #cccccc;}

	display: flex;
	justify-content: center;
	align-items: center;

	
}

.show-dinamic-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide-dinamic-modal {
	opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}