#subfraccionamiento {
	width: 200px;
}

#subfraccionamiento-modal {
	width: 220px;
}


.container-control{
    position: sticky;
    top: 72px;
    -webkit-position: sticky;
    //background-color: #f5f4f4;
    background-color: white;
    z-index: 3;

    .input-select {
        padding: 4px 8px 4px 8px;
    }

    .filts {
        width: 100%;

        .search-div {
            width: 100%;

            .input-clean {
                width: 40%;

                .input-filter {
                    width: 100%;
                    padding: 5px 12px 5px 35px;
                    //border: 1px solid #c4c8cb;
                }

                i {
                    width: 4%;
                    left: 13px;
                    top: 8px;
                    color: #9e9e9e;
                    cursor: none;
                    font-size: 0.9rem;
                }

                /*&:hover {
                    i {
                        color: #ea545fad;
                    }

                    .input-filter {
                        //color: #ea545fad;
                        border-bottom: 2px solid #ea545fad;
                    }
                }*/
            } 
        }

        .buttons-div {
            width: 100%;
        }
    }
}

.navigation {
    h3 {
        font-size: 1.5rem;
        color: $blue;
    }
}