.invitacion-detalle {
	.detalle-imagenes {
		display: grid;
		grid-template-columns: repeat(3,1fr);
    	grid-gap: 1.2rem;

		.container-img {
			background-color: #eee;
			width: 100%;

			img {
				width: auto;
				min-height: 170px;
				max-height: 170px;
			}
		}
	}
}