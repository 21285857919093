/**
*   fonts.scss
*   @description: Estilos de fuente y tipografía
*/

/*    Font families    */

$Mukta: "Mukta", sans-serif;

/*    Font sizes    */

$fontMini: 0.8rem;
$fontTiny: 0.9rem;
$fontText: 0.93rem;
$fontSmall: 1rem;
$fontRegular: 1.2rem;
$fontMedium: 1.4rem;
$fontLarge: 1.6rem;
$fontHuge: 1.8rem;
$fontDouble: 2rem;

.font-double {
	font-size: $fontDouble;
}

.font-huge {
	font-size: $fontHuge;
}

.font-large {
	font-size: $fontLarge;
}

.font-medium {
	font-size: $fontMedium;
}

.font-regular {
	font-size: $fontRegular;
}

.font-small {
	font-size: $fontSmall;
}

.font-text {
	font-size: $fontText;
}

.font-tiny {
	font-size: $fontTiny;
}

.font-mini {
	font-size: $fontMini;
}

/*    Font weight   */

$weightRegular: 400;
$weightMedium: 500;
$weightSemi: 600;
$weightBold: 700;

.weight-bold {
	font-weight: $weightBold;
}

.weight-semi {
	font-weight: $weightSemi;
}

.weight-medium {
	font-weight: $weightMedium;
}

.weight-regular {
	font-weight: $weightRegular;
}

a {
	font-family: $Mukta;
	font-size: $fontSmall;
}

li {
	font-family: $Mukta;
	font-size: $fontSmall;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
span,
td,
td,
tr {
	color: $dark;
	font-family: $Mukta;
}

h1 {
	font-size: $fontHuge;
	font-weight: $weightBold;
}

h2 {
	font-size: $fontLarge;
	font-weight: $weightBold;
}

h3 {
	font-size: $fontMedium;
	font-weight: $weightSemi;
}

h4 {
	font-size: $fontRegular;
	font-weight: $weightMedium;
}

h5 {
	font-size: $fontText;
	font-weight: $weightMedium;
}

h6 {
	font-size: $fontMedium;
	font-weight: $weightRegular;
}

p,
b,
span {
	font-size: $fontText;
	line-height: 22px;
	color: $black;
}

/*   Decorators    */

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.capitalize {
	text-transform: capitalize;
}

.underline {
	text-decoration: underline;
}

.line-through {
	text-decoration: line-through;
}

.truncate-ellipsis {
	width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

::selection {
	color: $white;
	background-color: $black;
}

/* Colores label para filas*/
.label-red {
    /*span{
        color: #ea4235;
	}
	color: #ea4235;*/
	span{
        color: $purple;
	}
	color: $purple;
}
