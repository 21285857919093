@mixin simple-modal {
    margin: 0;
    padding: 0;
    list-style: none;
}

$border-radius-input: 10px 10px 10px 10px;
$border-radius-span: 0px 0px 10px 10px;
.simple-modal-container{
    position:   fixed;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    z-index: 2;    
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;


    .simple-modal{
        align-self:     center;
        min-width:      40%;
        min-height:     80%;
        background:     $background;
        display:        flex;
        flex-direction: column;
        border-radius:  15px;

        .simple-header{
            width:          100%;
            border-radius:  10px 10px 0px 0px;
            background:     black;
            height:         60px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            
            p, i{
                color:          $blanco;
                font-size:      1.5rem;
                margin-right:   1rem;
                margin-left:    1rem;
            }

            &:hover{
                cursor: pointer;
            }
        }
        .simple-sub-header{
            background: rgb(142, 142, 142);
            // height: 70px;

            display: flex;
            justify-content: space-around;

            .simple-item{

                flex-grow: 1;
                flex-basis: 0;
                
                height: 40px;

                border-style: solid;
                border-color: rgb(250, 250, 250);
                border-width: 1px 2px 2px 0px;
                
                
                display:            flex;
                justify-content:    center;
                align-items:        center;
                transition:         all 300ms ease-in-out;

                &:last-child{
                    border-width: 1px 0px 2px 0px;
                } 
                p{
                    color: white;
                    font-size: 1.2rem;
                }
                &:hover{
                    background: #ea545f;
                    cursor: pointer;
                }
            }
            
            .simple-item-active {
                background: #ea545f;

                &:hover{
                    background: #f25862;
                    cursor: pointer;
                }
            }

            // &:nth-child(){
            //     background: red;
            // }
        }
        .simple-body{
            display:            flex;
            flex-direction:     column;
            justify-content:    center;
            align-items:        center;
            background:       white;
            // height: 100%; 
            flex-grow:          2;
            flex-basis:         1;

            .form-group{

                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                background: white;
                .form-item{
                    display: block;
                    // display: flex;
                    flex-direction: column;
                    color: black;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 9px;
                    border:1px solid lightgray;
                    margin-bottom: 30px;
                    border-radius: $border-radius-input;

                    label{
                        display: block;
                        float: left;
                        margin-top: -1rem;
                        background: #FFFFFF;
                        height: 1rem;
                        width: auto;
                        overflow: hidden !important;
                        padding: 2px 5px 2px 5px;
                        color: black;
                        font-size: .7rem;
                        font-family: "Mukta", sans-serif;
                        font-weight: bold;
                    }

                    input[type="text"],
                    input[type="date"],
                    input[type="datetime"],
                    input[type="email"],
                    input[type="number"],
                    input[type="search"],
                    input[type="time"],
                    input[type="url"],
                    input[type="password"],
                    textarea,
                    select {
                        
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        width: 100%;
                        display: block;
                        outline: none;
                        border: none;
                        height: 2rem;
                        line-height: 25px;
                        font-size: 1rem;
                        padding: 0;
                        font-family:"Mukta", sans-serif;

                        text-align: center;
                        text-align-last: center;
                    }

                    span{
                        background: #F3F3F3;
                        display: block;
                        padding: 3px;
                        margin: 0 -9px -9px -9px;
                        text-align: center;
                        color: black;
                        font-family: "Mukta", sans-serif;
                        font-size: .6rem;
                        border-radius:  $border-radius-span;
                    }

                    
                }

                .error{
                    border:1px solid red !important;
                    label,input,span{
                        color: red;
                    }
                    span{
                        background:rgb(253, 192, 192);;
                    }
                    
                }

                .group-double{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .form-item{
                        width: 48%;
                    }
                }
                img{
                    width: 400px;
                    padding: 1rem ;
                }
            }



        }
        .super-footer{
            background: white;
            height: 80px;
            border-radius: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .super-btn{
                @include superBotones;
                
            }

            .next-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                button{
                    @include superBotones;
                }
            }
        }
    }
}

// SEGUNDO MODAL 222


.DOSsimple-modal-container{
    position:   fixed;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    z-index: 2;    
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;


    .simple-modal{
        align-self:     center;
        min-width:      40%;
        min-height:     80%;
        background:     $background;
        display:        flex;
        flex-direction: column;
        border-radius:  15px;

        .simple-header{
            width:          100%;
            border-radius:  10px 10px 0px 0px;
            background:     black;
            height:         60px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            
            p, i{
                color:          $blanco;
                font-size:      1.5rem;
                margin-right:   1rem;
                margin-left:    1rem;
            }

            &:hover{
                cursor: pointer;
            }
        }
        .simple-sub-header{
            background: rgb(142, 142, 142);
            // height: 70px;

            display: flex;
            justify-content: space-around;

            .simple-item{

                flex-grow: 1;
                flex-basis: 0;
                
                height: 40px;

                border-style: solid;
                border-color: rgb(250, 250, 250);
                border-width: 1px 2px 2px 0px;
                
                
                display:            flex;
                justify-content:    center;
                align-items:        center;
                transition:         all 300ms ease-in-out;

                &:last-child{
                    border-width: 1px 0px 2px 0px;
                } 
                p{
                    color: white;
                    font-size: 1.2rem;
                }
                &:hover{
                    background: #ea545f;
                    cursor: pointer;
                }
            }
            
            .simple-item-active {
                background: #ea545f;

                &:hover{
                    background: #f25862;
                    cursor: pointer;
                }
            }

            // &:nth-child(){
            //     background: red;
            // }
        }
        .simple-body{
            display:            flex;
            flex-direction:     column;
            justify-content:    center;
            align-items:        center;
            background:       white;
            // height: 100%; 
            flex-grow:          2;
            flex-basis:         1;

            .form-group{

                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                background: white;
                .form-item{
                    display: block;
                    // display: flex;
                    flex-direction: column;
                    color: black;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 9px;
                    border:1px solid lightgray;
                    margin-bottom: 30px;
                    border-radius: $border-radius-input;
                    border: none;
                    border-bottom: 1px solid lightgray;

                    label{
                        display: block;
                        float: left;
                        margin-top: -1rem;
                        background: #FFFFFF;
                        height: 1rem;
                        width: auto;
                        overflow: hidden !important;
                        padding: 2px 5px 2px 5px;
                        color: black;
                        font-size: .7rem;
                        font-family: "Mukta", sans-serif;
                        font-weight: bold;
                    }

                    input[type="text"],
                    input[type="date"],
                    input[type="datetime"],
                    input[type="email"],
                    input[type="number"],
                    input[type="search"],
                    input[type="time"],
                    input[type="url"],
                    input[type="password"],
                    textarea,
                    select {
                        
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        width: 100%;
                        display: block;
                        outline: none;
                        border: none;
                        height: 2rem;
                        line-height: 25px;
                        font-size: 1rem;
                        padding: 0;
                        font-family:"Mukta", sans-serif;

                        text-align: center;
                        text-align-last: center;
                        // background: rgb(238, 238, 238);
                    }

                    span{
                        background: #F3F3F3;
                        display: block;
                        padding: 3px;
                        margin: 0 -9px -9px -9px;
                        text-align: center;
                        color: black;
                        font-family: "Mukta", sans-serif;
                        font-size: .6rem;
                        border-radius:  $border-radius-span;
                    }

                    
                }

                .error{
                    border-bottom: red;
                    label,input,span{
                        color: red;
                    }
                    span{
                        background-color: rgb(253, 192, 192);
                    }
                    
                }

                .group-double{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .form-item{
                        width: 48%;
                    }
                }
                img{
                    width: 400px;
                    padding: 1rem ;
                }
            }



        }
        .super-footer{
            background: white;
            height: 80px;
            border-radius: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .super-btn{
                @include superBotones;
                
            }

            .next-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                button{
                    @include superBotones;
                }
            }
        }
    }
}



// MODAL 3


.TRESsimple-modal-container{
    position:   fixed;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    z-index: 2;    
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;


    .simple-modal{
        align-self:     center;
        min-width:      40%;
        min-height:     60%;
        background:     $background;
        display:        flex;
        flex-direction: column;
        border-radius:  15px;

        .simple-header{
            width:          100%;
            border-radius:  10px 10px 0px 0px;
            background:     black;
            height:         60px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            
            p, i{
                color:          $blanco;
                font-size:      1.5rem;
                margin-right:   1rem;
                margin-left:    1rem;
            }

            &:hover{
                cursor: pointer;
            }
        }
        .simple-sub-header{
            background: rgb(142, 142, 142);
            // height: 70px;

            display: flex;
            justify-content: space-around;

            .simple-item{

                flex-grow: 1;
                flex-basis: 0;
                
                height: 40px;

                border-style: solid;
                border-color: rgb(250, 250, 250);
                border-width: 1px 2px 2px 0px;
                
                
                display:            flex;
                justify-content:    center;
                align-items:        center;
                transition:         all 300ms ease-in-out;

                &:last-child{
                    border-width: 1px 0px 2px 0px;
                } 
                p{
                    color: white;
                    font-size: 1.2rem;
                }
                &:hover{
                    background: #ea545f;
                    cursor: pointer;
                }
            }
            
            .simple-item-active {
                background: #ea545f;

                &:hover{
                    background: #f25862;
                    cursor: pointer;
                }
            }

            // &:nth-child(){
            //     background: red;
            // }
        }
        .simple-body{
            display:            flex;
            flex-direction:     column;
            justify-content:    center;
            align-items:        center;
            background:       white;
            // height: 100%; 
            flex-grow:          2;
            flex-basis:         1;

            .form-group{

                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                background: white;
                .form-item{
                    display: block;
                    // display: flex;
                    flex-direction: column;
                    color: black;
                    align-items: center;
                    justify-content: center;
                    width: 48%;
                    padding: 9px;
                    border:1px solid lightgray;
                    margin-bottom: 30px;
                    border-radius: $border-radius-input;
                    border: none;
                    border-bottom: 1px solid lightgray;
                    
                    label{
                        display: block;
                        float: left;
                        margin-top: -1rem;
                        background: #FFFFFF;
                        height: 1rem;
                        width: auto;
                        overflow: hidden !important;
                        padding: 2px 5px 2px 5px;
                        color: black;
                        font-size: .7rem;
                        font-family: "Mukta", sans-serif;
                        font-weight: bold;
                    }

                    input[type="text"],
                    input[type="date"],
                    input[type="datetime"],
                    input[type="email"],
                    input[type="number"],
                    input[type="search"],
                    input[type="time"],
                    input[type="url"],
                    input[type="password"],
                    textarea,
                    select {
                        
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        width: 100%;
                        display: block;
                        outline: none;
                        border: none;
                        height: 2rem;
                        line-height: 25px;
                        font-size: 1rem;
                        padding: 0;
                        font-family:"Mukta", sans-serif;

                        text-align: center;
                        text-align-last: center;
                        // background: rgb(238, 238, 238);
                    }

                    span{
                        background: #F3F3F3;
                        display: block;
                        padding: 3px;
                        margin: 0 -9px -9px -9px;
                        text-align: center;
                        color: black;
                        font-family: "Mukta", sans-serif;
                        font-size: .6rem;
                        border-radius:  $border-radius-span;
                    }

                    
                }

                .error{
                    border-bottom: red;
                    label,input,span{
                        color: red;
                    }
                    span{
                        background-color: rgb(253, 192, 192);
                    }
                    
                }

                .group-double{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    align-content: center;
                    align-items: center;
                    .form-item{
                        width: 48%;
                    }
                }
                img{
                    width: 400px;
                    padding: 1rem ;
                }
            }



        }
        .super-footer{
            background: white;
            height: 80px;
            border-radius: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .super-btn{
                @include superBotones;
                
            }

            .next-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                button{
                    @include superBotones;
                }
            }
        }
    }
}


















