/**
*   card-incident.scss
*   @description: Estilos de tarjetas de incidencias
*/

// .card-owners {

//     //.name { width: 20%; }

//     //.description { width: 50%; }

//     //.description-detail { font-size: $fontText; }

//     //.vivienda { width: 10%; }

//     //.fecha { width: 20%; }

//     //td { border-bottom: 2px solid $light; }    

// }

/**
*   card-incident.scss
*   @description: Estilos de tabla detalle de perfil
*/

.profile-detail {
    width: 100%;

    .content {
        width: 100%;
        padding: 8px;

        .menus {
            width: 50%;

            .no-select {
                cursor: pointer;
                background: gray;
                margin: 0 6px;
                padding: 2px 0;

                .butn {
                    cursor: pointer;
                    font-family: $Mukta;
                    font-size: 0.9rem;
                    font-weight: $weightSemi;
                    transition: all 200ms ease-in-out;

                    &:active {
                        transform: scale(0.95);
                    }

                    color: white;
                }
            }

            .option {
                background: $red;
                cursor: pointer;
                margin: 0 6px;
                padding: 2px 0;

                &:hover {
                    border: 1px solid;
                }

                .butn {
                    color: white;
                }
            }
        }

        .detail-table {
            border: 1px solid #b9b9b9;
            box-shadow: 0px 0 2px 2px #eee;
            border-radius: 4px;
            padding: 12px;

            .menu2 {
                border-bottom: 1px solid gray;
                padding-bottom: 4px;

                h4 {
                    font-weight: 700;
                    color: gray;
                }
            }

            .btn-container {
                button {
                    width: 20%;
                    padding: 4px 8px;
                }

                /*.btn-primary {
                    margin-right: 8px;
                }

                .btn-secondary {
                    background: #6c757d;
                    color: white;
                }*/
            }

            /*.container-complete {
                //border: 1px solid black;
                max-height: 300px;
                overflow-y: scroll;
            }*/
        }
    }
}

.dias-feriados {
    margin-left: 32px;
    padding-right: 16px;
    height: 200px;
    overflow-y: auto;

    .sticky-th {
        background-color: #fff;

        th {
            position: sticky;
            background-color: #fff;
            top: 0px;
            z-index: 1;
            font-weight: 500;
            color: #737373;
        }
    }
}