.row-medium {
    width: 66%;
}

.column-duo{
    padding-left: 24px;
    .row-duo-medium {
        width: 85%;
    }
}

.space-row{
	margin-left: $marginMedium;
}

.input-form-content  {
	.label-gray {
		background-color: #eaeaea;
    	padding: 2px 16px;
	}

	.text-area-gray {
		background-color: #eaeaea;
		padding: 8px;
		width: 100%;
		font-size: $fontText;
		//min-height: 100px;
	}

    /* Estilos label para filas con un solo input */

	.label-normal { 
		width: 20%;
    }
    .label-small { 
		width: 25%;
	}

	.label-extra { 
		width: 28%;
	}

	.label-medium { 
		width: 30%;
	}

	.label-big { 
		width: 45%;
    }
    
    .label-medium-row { 
		width: 42%;
	}

/* Estilos label para filas con dos inputs */
	.label-duo-huge {
		width: 115%;
	}

	.label-duo-big {
		width: 76%;
	}

	.label-duo-second{
		width: 70%;
	}

	.label-duo-normal {
		width: 53%;
	}

	.label-duo-medium {
		width: 77%;
	}

    .label-duo-small {
        width: 40%;
        margin-right: 16px;
    }
    
/* Estilos label para filas con tres inputs */

	.label-triple-huge {
		width: 130%;
	}

	.space {
		margin-left: $marginMedium;
	}

	.space-equal{
		margin-left: 10px;
		margin-right: 10px;
	}
}

.space-m{
	padding-left: 23;
	margin-right: -7;
}

/* Estilos nueva área común */

.column-img {
	width: 75%;

  .container-img{
	  min-width: 250px;
  }
}

.table-footer {
    .td-big {
        width: 54% !important;
    }

    tr {
        background-color: white !important;
    }
}

.cont-table-fake{
	display: flex;
	flex-direction: column;
	border: 1px solid #e6e6e6;
	width: 170px;

	.tlt-table-fake {
		font-weight: 100;
		font-size: 16px;
		width: 100%;
		height: 50%;
		background-color: #e6e6e6;
		text-align: center;
		color:#757575;
	}
}