.paginator {
    .page {
        padding: 4px 13px;
        //background-color: black;
        color: black;
        cursor: pointer;
        font-size: $fontSmall;
        //font-weight: $weightBold;
        font-weight: 500;
        border-radius: 4px;
        margin: 4px;
        transition: background-color 300ms;

        &:hover {
            background-color: #9e9e9e75;
        }
    }

    .active {
        background-color: $blue;
        color: white;
    }

    .input-select{
        width: 100px;
        padding: 4px ;
    }
}