#sidebar {
	// height: 100%;
	width: 0;
	position: sticky;
	height: calc(100vh - 72px);
	z-index: 1;
	top: 72px;
	color: $white;
	left: 0;
	//background-color: $black;
	overflow-x: hidden !important;
	transition: 300ms;
	background-image: url("../../img/fondoBlack.png");
	background-position: center;
    background-size: cover;

	.accordion {
		//background-color: $black;
		color: $white;
		cursor: pointer;
		min-width: 270px;
		width: 100%;
		position: relative;
		padding: 4px 16px;
		font-size: $fontRegular;
		border: none;
		text-align: left;
		outline: none;
		transition: background-color 0.3s ease-in-out;

		i {
			position: absolute;
			right: 18px;
			top: 12px;
		}
	}

	.active,
	.accordion:hover {
		background-color: $blue;
	}

	.panel {
		//background-color: $black;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
	}

	.content-side {
		width: 100%;
		.nav-item {
			padding-left: $paddingMedium;
			padding: 0px 32px;
			transition: 300ms;

			&:hover {
				background-color: #6c757d8a;
			}
		}

		.active-item {
			background-color: #6c757d8a;
		}
	}
}

#sidebar::-webkit-scrollbar {
	width: 10px;
}
#sidebar::-webkit-scrollbar-thumb {
	background-color: #cccccc;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

#content {
	transition: all 300ms;
	width: 100%;
	padding-bottom: 32px;
}

.maxHeight {
	max-height: 136px !important;
}

/*LEFT SIDEBAR*/
.rightsidebar {
	position: fixed;
	right: 0px;
	background-color: $blue;
	height: 100%;
	width: 0px;
	overflow-x: hidden;
	transition: 300ms;
	z-index: 3;
	padding-top: 100px;

	&.open {
		width: 220px;
		//padding-left: 10px;
		z-index: 4;
	}
}
.nav-exit:hover {
	color: $primary;
}

/*Cambio de contraseña*/

.change-password {
	.box {
		width: 85%;

		.labelpass {
			padding-right: 12px;
		}
	}
}
