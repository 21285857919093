#card-owners {
    .info-content {
        width: 90%;

        .configuration-rows {
            padding-top: 12px;
            padding-left: 65px;
        }

        .btn {
            padding: 6px 24px;
        }

        .btn-secondary {
            background-color: $secondary !important;
            color: white;
            margin-left: 16px;
        }
    }

    .info-openpay {
        width: 75%;

        .btn {
            padding: 6px 24px;
        }

        .btn-secondary {
            background-color: $secondary !important;
            color: white;
            margin-left: 16px;
        }
    }
    
    .edit-button {
        padding: 10px 0;
        border-bottom: 2px solid rgba(108, 117, 125, 0.4);
    }
}