/**
*   colors.scss
*   @description: Variables de colores
*/

$black:             #000000;
$white:             #FFFFFF;
$dark:              #4e4a4a;
$gray:              #9f9f9f;
$light:             #f8f9fa;
$red:               #dc3545;
$yellow:            #ffc107;
$green:             #28a745;
$blue:              #06003c;
$purple:            #6d2e87;

$primary:           #EA545F;/*Color salmon*/
$secondary:         #6c757d;
$success:           #28a745;
$info:              #17a2b8;
$warning:           #ffc107;
$danger:            #C1272D;/**/


/* Classes de colores */

.color-white { color: $white; }

.color-black { color: $black; }

.color-dark { color: $dark; }

.color-primary { color: $primary; }

.color-secondary { color: $secondary; }

.color-success { color: $success; }

.color-info { color: $info; }

.color-warning { color: $warning; }

.color-danger { color: $purple; }

.color-green { color: $green; }

.color-red { color: $red; }

.color-purple { color: $purple; }
