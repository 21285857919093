.card-info {
    position: relative;
    border-radius: 8px;
    border: 1px solid $primary;

    .box-header {
        padding: 0 2px;
        background-color: white;
        position: absolute;
        top: -11px;
        width: auto;
        left: 14px;
        
        p {
            color: $primary;
            font-weight: 500;
            font-size: 1rem;
        }
    }

    .box-body-small {
        padding: 16px 24px;
    }

    .box-body-big {
        padding: 24px;
    }
}

.card-info-purple {
    position: relative;
    border-radius: 8px;
    border: 1px solid $purple;

    .box-header {
        padding: 0 2px;
        background-color: white;
        position: absolute;
        top: -11px;
        width: auto;
        //left: 14px;
        left: 16px;
        
        p {
            color: $purple;
            //font-weight: 500;
            //font-size: 1rem;
            font-size: 0.87rem;
        }
    }

    .box-body-small {
        padding: 16px 24px;
    }

    .box-body-big {
        padding: 24px;
    }
}

//Recuadro de número en Editar concepto-General
.num {
    width: 32%;
    padding: 0px 16px;
}

//Tool
.margin-left-16 {
    margin-left: 16px;
}