.container-img {
	position: relative;
	width: 90%;
	background-color: #eee;
	//min-width: 328px;

	.img-duo-preview {
		min-height: 180px;
		max-height: 180px;
		//width: 100%;
		object-fit: fill;
	}

	
	.image-area {
		opacity: 1;
		display: block;
		width: 100%;
		height: auto;
		transition: .5s ease;
		backface-visibility: hidden;
	}
	
	.middle-overlay {
		transition: .5s ease;
		opacity: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: #6d2e8759;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		text-align: center;
		.btn-upload-image {
			// min-width: 328px;
			width: 100%;
			height: 180px;
			cursor: pointer;

			input[type="file"] { display: none; }
			i {
				font-size: 3.5rem;
				color: white;
				transition: color 300ms ease-in-out;
				
			}

			
		}
		
	}

	&:hover {
		.image-area {	
			opacity: 0.3;
		}
	
		.middle-overlay {
			opacity: 1;
		}
	}
}
  
.group {
    border: 1px solid #6d2e8780;
    padding: 12px 16px;
    border-radius: 6px;
}

.fechas-bloqueadas {
	//border: 1px solid gray;
    //padding: 8px;
    //border-radius: 3px;
    margin-left: 32px;
    max-height: 174px;
    height: 200px;
	overflow-y: auto;
}