

.table-asistencia-container {
    height: 450px;
    overflow-y: scroll;
}

.table-asistencia{
    width: 100%;
    position: relative;

    th {
        position: sticky;
        background-color: $white;
        top: 0;
        z-index: 3;
    }

    .container-check{
        margin-left: 40%;
    }


    .row-space {
        td, th {
            padding: 4px 24px;
        }
    }


    tfoot {
        tr {
            td{
                position: sticky;
                bottom: 0px;
                background-color: $white;
            }
        }
    }

}


.css-1okebmr-indicatorSeparator{
    background-color: transparent!important;
    display: none;
}